import React from "react";

import checkboxOff from "../images/glyphs/checkboxOff.svg";
import checkboxOffRed from "../images/glyphs/checkboxOffRed.svg";
import checkboxOn from "../images/glyphs/checkboxOn.svg";
import close from "../images/glyphs/close.svg";
import close2 from "../images/glyphs/close2.svg";
import prev from "../images/glyphs/prev.svg";
import next from "../images/glyphs/next.svg";
import tick from "../images/glyphs/tick.svg";
import upvote from "../images/glyphs/upvote.svg";
import chevron from "../images/glyphs/chevron.svg";
import question from "../images/glyphs/question.svg";

import proHero1 from "../images/pro/hero-1.svg";
import proHero2 from "../images/pro/hero-2.svg";
import proHero3 from "../images/pro/hero-3.svg";
import proArrow from "../images/pro/arrow.svg";
import proFace1 from "../images/pro/face01.svg";
import proFace2 from "../images/pro/face02.svg";
import proFace3 from "../images/pro/face03.svg";
import proFace4 from "../images/pro/face04.svg";
import proFace5 from "../images/pro/face05.svg";
import proFace6 from "../images/pro/face06.svg";
import proFace7 from "../images/pro/face07.svg";
import proFeature1 from "../images/pro/feature01.svg";
import proFeature2 from "../images/pro/feature02.svg";
import proFeature3 from "../images/pro/feature03.svg";
import proFeature4 from "../images/pro/feature04.svg";
import proIssuePreview from "../images/pro/issue-preview.svg";
import proSource1 from "../images/pro/source01.svg";
import proSource2 from "../images/pro/source02.svg";
import proSource3 from "../images/pro/source03.svg";
import proSource4 from "../images/pro/source04.svg";

import feature1 from "../images/features/feature-1.svg";
import feature2 from "../images/features/feature-2.svg";
import feature3 from "../images/features/feature-3.svg";

import customNewsletter from "../images/other/custom-newsletter.svg";

import logo2 from "../images/logo/logo2.svg";

import logoDefaultBig from "../images/logo/default-big.svg";
import logoDefaultMedium from "../images/logo/default-medium.svg";
import logoDefaultSmall from "../images/logo/default-small.svg";

import logoProBig from "../images/logo/pro-big.svg";
import logoProMedium from "../images/logo/pro-medium.svg";
import logoProSmall from "../images/logo/pro-small.svg";

import mailbrewLogo from "../images/logo/mailbrew_logo.svg";

import iconApple from "../images/newsletters/bg-apple.svg";
import iconGoT from "../images/newsletters/bg-game-of-thrones.svg";
import iconDeals from "../images/newsletters/bg-deals.svg";
import iconCinema from "../images/newsletters/bg-cinema.svg";
import iconCrypto from "../images/newsletters/bg-crypto.svg";
import iconCustom from "../images/newsletters/bg-custom.svg";
import iconPro from "../images/newsletters/bg-pro.svg";
import iconDesign from "../images/newsletters/bg-design.svg";
import iconEntrepreneur from "../images/newsletters/bg-entrepreneur.svg";
import iconFiction from "../images/newsletters/bg-fiction.svg";
import iconFilmmaking from "../images/newsletters/bg-filmmaking.svg";
import iconFitness from "../images/newsletters/bg-fitness.svg";
import iconFrontend from "../images/newsletters/bg-frontend.svg";
import iconIndieMakers from "../images/newsletters/bg-indie-makers.svg";
import iconInspirational from "../images/newsletters/bg-inspirational.svg";
import iconGeek from "../images/newsletters/bg-geek.svg";
import iconPhotography from "../images/newsletters/bg-photography.svg";
import iconPictures from "../images/newsletters/bg-pictures.svg";
import iconReact from "../images/newsletters/bg-react.svg";
import iconSelfImprovement from "../images/newsletters/bg-self-improvement.svg";
import iconTech from "../images/newsletters/bg-tech.svg";
import iconTechnology from "../images/newsletters/bg-technology.svg";
import iconVRAR from "../images/newsletters/bg-VR-AR.svg";
import iconWorldNews from "../images/newsletters/bg-world-news.svg";
import iconWriting from "../images/newsletters/bg-writing.svg";
import iconiOSDev from "../images/newsletters/bg-ios-dev.svg";
import iconBlockchain from "../images/newsletters/bg-blockchain.svg";
import iconGrowth from "../images/newsletters/bg-growth-and-marketing.svg";
import iconWallpapers from "../images/newsletters/bg-wallpapers.svg";
import iconGaming from "../images/newsletters/bg-gaming.svg";
import iconFunny from "../images/newsletters/bg-funny.svg";

import iconMindfulness from "../images/newsletters/bg-mindfulness.svg";
import iconAndroid from "../images/newsletters/bg-android.svg";
import iconPersonalFinance from "../images/newsletters/bg-personal-finance.svg";
import iconPhilosophy from "../images/newsletters/bg-philosophy.svg";
import iconCybersecurity from "../images/newsletters/bg-cybersecurity.svg";
import iconTravel from "../images/newsletters/bg-travel.svg";

export default {
  features: {
    feature1: (
      <svg width="170" height="170">
        <use xlinkHref={`#${feature1.id}`} />
      </svg>
    ),
    feature2: (
      <svg width="170" height="170">
        <use xlinkHref={`#${feature2.id}`} />
      </svg>
    ),
    feature3: (
      <svg width="170" height="170">
        <use xlinkHref={`#${feature3.id}`} />
      </svg>
    )
  },
  pro: {
    hero1: (
      <svg width="324" height="224">
        <use xlinkHref={`#${proHero1.id}`} />
      </svg>
    ),
    hero2: (
      <svg width="324" height="224">
        <use xlinkHref={`#${proHero2.id}`} />
      </svg>
    ),
    hero3: (
      <svg width="324" height="224">
        <use xlinkHref={`#${proHero3.id}`} />
      </svg>
    ),
    arrow: (
      <svg width="34" height="14">
        <use xlinkHref={`#${proArrow.id}`} />
      </svg>
    ),
    face1: (
      <svg width="85" height="85">
        <use xlinkHref={`#${proFace1.id}`} />
      </svg>
    ),
    face2: (
      <svg width="85" height="85">
        <use xlinkHref={`#${proFace2.id}`} />
      </svg>
    ),
    face3: (
      <svg width="85" height="85">
        <use xlinkHref={`#${proFace3.id}`} />
      </svg>
    ),
    face4: (
      <svg width="85" height="85">
        <use xlinkHref={`#${proFace4.id}`} />
      </svg>
    ),
    face5: (
      <svg width="85" height="85">
        <use xlinkHref={`#${proFace5.id}`} />
      </svg>
    ),
    face6: (
      <svg width="85" height="85">
        <use xlinkHref={`#${proFace6.id}`} />
      </svg>
    ),
    face7: (
      <svg width="85" height="85">
        <use xlinkHref={`#${proFace7.id}`} />
      </svg>
    ),
    feature1: (
      <svg width="40" height="40">
        <use xlinkHref={`#${proFeature1.id}`} />
      </svg>
    ),
    feature2: (
      <svg width="40" height="40">
        <use xlinkHref={`#${proFeature2.id}`} />
      </svg>
    ),
    feature3: (
      <svg width="40" height="40">
        <use xlinkHref={`#${proFeature3.id}`} />
      </svg>
    ),
    feature4: (
      <svg width="40" height="40">
        <use xlinkHref={`#${proFeature4.id}`} />
      </svg>
    ),
    issuePreview: (
      <svg width="465" height="432" preserveAspectRatio="xMidYMin slice">
        <use xlinkHref={`#${proIssuePreview.id}`} />
      </svg>
    ),
    source1: (
      <svg width="475" height="100" preserveAspectRatio="xMidYMin slice">
        <use xlinkHref={`#${proSource1.id}`} />
      </svg>
    ),
    source2: (
      <svg width="475" height="100" preserveAspectRatio="xMidYMin slice">
        <use xlinkHref={`#${proSource2.id}`} />
      </svg>
    ),
    source3: (
      <svg width="475" height="100" preserveAspectRatio="xMidYMin slice">
        <use xlinkHref={`#${proSource3.id}`} />
      </svg>
    ),
    source4: (
      <svg width="475" height="100" preserveAspectRatio="xMidYMin slice">
        <use xlinkHref={`#${proSource4.id}`} />
      </svg>
    )
  },
  logo: {
    logo2: (
      <svg width="106" height="106">
        <use xlinkHref={`#${logo2.id}`} />
      </svg>
    ),
    mailbrew: (
      <svg width="128" height="128">
        <use xlinkHref={`#${mailbrewLogo.id}`} />
      </svg>
    ),
    default: {
      big: (
        <svg width="50" height="50">
          <use xlinkHref={`#${logoDefaultBig.id}`} />
        </svg>
      ),
      medium: (
        <svg width="30" height="30">
          <use xlinkHref={`#${logoDefaultMedium.id}`} />
        </svg>
      ),
      small: (
        <svg width="16" height="16">
          <use xlinkHref={`#${logoDefaultSmall.id}`} />
        </svg>
      )
    },

    pro: {
      big: (
        <svg width="50" height="50">
          <use xlinkHref={`#${logoProBig.id}`} />
        </svg>
      ),
      medium: (
        <svg width="30" height="30">
          <use xlinkHref={`#${logoProMedium.id}`} />
        </svg>
      ),
      small: (
        <svg width="16" height="16">
          <use xlinkHref={`#${logoProSmall.id}`} />
        </svg>
      )
    }
  },
  glyphs: {
    checkboxOff: (
      <svg>
        <use xlinkHref={`#${checkboxOff.id}`} />
      </svg>
    ),
    checkboxOffRed: (
      <svg>
        <use xlinkHref={`#${checkboxOffRed.id}`} />
      </svg>
    ),
    checkboxOn: (
      <svg>
        <use xlinkHref={`#${checkboxOn.id}`} />
      </svg>
    ),
    close: (
      <svg width="30" height="30">
        <use xlinkHref={`#${close.id}`} />
      </svg>
    ),
    close2: (
      <svg width="30" height="30">
        <use xlinkHref={`#${close2.id}`} />
      </svg>
    ),
    prev: (
      <svg>
        <use xlinkHref={`#${prev.id}`} />
      </svg>
    ),
    next: (
      <svg>
        <use xlinkHref={`#${next.id}`} />
      </svg>
    ),
    tick: (
      <svg width="20" height="20">
        <use xlinkHref={`#${tick.id}`} />
      </svg>
    ),
    upvote: (
      <svg>
        <use xlinkHref={`#${upvote.id}`} />
      </svg>
    ),
    chevron: (
      <svg width="40" height="40">
        <use xlinkHref={`#${chevron.id}`} />
      </svg>
    ),
    question: (
      <svg width="16" height="16">
        <use xlinkHref={`#${question.id}`} />
      </svg>
    )
  },
  illustrations: {},
  other: {
    customNewsletter: (
      <svg width="139" height="163">
        <use xlinkHref={`#${customNewsletter.id}`} />
      </svg>
    )
  },
  newsletters: {
    apple: (
      <svg>
        <use xlinkHref={`#${iconApple.id}`} />
      </svg>
    ),
    "game-of-thrones": (
      <svg>
        <use xlinkHref={`#${iconGoT.id}`} />
      </svg>
    ),
    deals: (
      <svg>
        <use xlinkHref={`#${iconDeals.id}`} />
      </svg>
    ),
    cinema: (
      <svg>
        <use xlinkHref={`#${iconCinema.id}`} />
      </svg>
    ),
    crypto: (
      <svg>
        <use xlinkHref={`#${iconCrypto.id}`} />
      </svg>
    ),
    custom: (
      <svg>
        <use xlinkHref={`#${iconCustom.id}`} />
      </svg>
    ),
    pro: (
      <svg>
        <use xlinkHref={`#${iconPro.id}`} />
      </svg>
    ),
    design: (
      <svg>
        <use xlinkHref={`#${iconDesign.id}`} />
      </svg>
    ),
    entrepreneur: (
      <svg>
        <use xlinkHref={`#${iconEntrepreneur.id}`} />
      </svg>
    ),
    fiction: (
      <svg>
        <use xlinkHref={`#${iconFiction.id}`} />
      </svg>
    ),
    filmmaking: (
      <svg>
        <use xlinkHref={`#${iconFilmmaking.id}`} />
      </svg>
    ),
    fitness: (
      <svg>
        <use xlinkHref={`#${iconFitness.id}`} />
      </svg>
    ),
    frontend: (
      <svg>
        <use xlinkHref={`#${iconFrontend.id}`} />
      </svg>
    ),
    "indie-makers": (
      <svg>
        <use xlinkHref={`#${iconIndieMakers.id}`} />
      </svg>
    ),
    inspirational: (
      <svg>
        <use xlinkHref={`#${iconInspirational.id}`} />
      </svg>
    ),
    geek: (
      <svg>
        <use xlinkHref={`#${iconGeek.id}`} />
      </svg>
    ),
    photography: (
      <svg>
        <use xlinkHref={`#${iconPhotography.id}`} />
      </svg>
    ),
    pictures: (
      <svg>
        <use xlinkHref={`#${iconPictures.id}`} />
      </svg>
    ),
    react: (
      <svg>
        <use xlinkHref={`#${iconReact.id}`} />
      </svg>
    ),
    "self-improvement": (
      <svg>
        <use xlinkHref={`#${iconSelfImprovement.id}`} />
      </svg>
    ),
    tech: (
      <svg>
        <use xlinkHref={`#${iconTech.id}`} />
      </svg>
    ),
    technology: (
      <svg>
        <use xlinkHref={`#${iconTechnology.id}`} />
      </svg>
    ),
    "VR-AR": (
      <svg>
        <use xlinkHref={`#${iconVRAR.id}`} />
      </svg>
    ),
    "world-news": (
      <svg>
        <use xlinkHref={`#${iconWorldNews.id}`} />
      </svg>
    ),
    writing: (
      <svg>
        <use xlinkHref={`#${iconWriting.id}`} />
      </svg>
    ),
    "ios-dev": (
      <svg>
        <use xlinkHref={`#${iconiOSDev.id}`} />
      </svg>
    ),
    blockchain: (
      <svg>
        <use xlinkHref={`#${iconBlockchain.id}`} />
      </svg>
    ),
    "growth-and-marketing": (
      <svg>
        <use xlinkHref={`#${iconGrowth.id}`} />
      </svg>
    ),
    wallpapers: (
      <svg>
        <use xlinkHref={`#${iconWallpapers.id}`} />
      </svg>
    ),
    gaming: (
      <svg>
        <use xlinkHref={`#${iconGaming.id}`} />
      </svg>
    ),
    funny: (
      <svg>
        <use xlinkHref={`#${iconFunny.id}`} />
      </svg>
    ),

    mindfulness: (
      <svg>
        <use xlinkHref={`#${iconMindfulness.id}`} />
      </svg>
    ),
    android: (
      <svg>
        <use xlinkHref={`#${iconAndroid.id}`} />
      </svg>
    ),
    "personal-finance": (
      <svg>
        <use xlinkHref={`#${iconPersonalFinance.id}`} />
      </svg>
    ),
    philosophy: (
      <svg>
        <use xlinkHref={`#${iconPhilosophy.id}`} />
      </svg>
    ),
    cybersecurity: (
      <svg>
        <use xlinkHref={`#${iconCybersecurity.id}`} />
      </svg>
    ),
    travel: (
      <svg>
        <use xlinkHref={`#${iconTravel.id}`} />
      </svg>
    )
  }
};
