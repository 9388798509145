import React from "react";
import styled, { keyframes } from "styled-components";
import colors from "../components/colors";
import { Link } from "gatsby";

const loadingAni = keyframes`
  50% {
    opacity: .35;
  }
`;

const ButtonWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  
  font-size: ${props => (props.big ? "1.4em" : "1.3em")};
  text-align: center;
  line-height: 1.5;
  font-weight: 500;
  border: none;
  border-radius: 8px;
  cursor: pointer;

  padding: 0.3em 1em;

  /* secondary style by default */

  ${props =>
    props.white
      ? `
    color: ${colors.accent};
    background: white;
  `
      : `
    color: white;
    background: ${colors.accent};`}

  animation: ${props => props.loading && loadingAni} 1s .3s linear infinite;
  animation-timing-function: ease-in-out;

  box-shadow: 0px 6px 7px rgba(239, 27, 27, 0.1);

  outline: none;

  ${props =>
    !props.secondary &&
    `
    transition: .3s cubic-bezier(.3, 0, .1, 1);

    :hover {
      transform: scale(1.03);
      box-shadow: 0px 10px 7px rgba(239, 27, 27, 0.1);
    }
  `}

  ${props =>
    props.secondary &&
    `
    color: ${colors.accent};
    background: transparent;
    box-shadow: inset 0px 0px 0px 2px ${colors.accent};
  `}

  ${props =>
    props.tertiary &&
    `
    color: ${colors.accent};
    background: ${colors.accentTransparent};
    box-shadow: none;
    :hover {
      box-shadow: none;
    }
  `}

  ${props =>
    props.right &&
    `
    float: right;
    margin-left: .5em;
  `}

  ${props =>
    props.disabled &&
    `
    opacity: 0.7;
    cursor: not-allowed;
  `}

  ${props =>
    props.small &&
    `
    font-size: 1.1em;
  `}
`;

const Button = props => {
  const { label, href, to, onClick } = props;

  const scrollable = href ? true : false;

  return (
    <>
      {(href || onClick) && (
        <a href={href} style={{ textDecoration: "none" }} target="_blank">
          <ButtonWrapper onClick={onClick} data-scroll={scrollable} target="_blank" {...props}>
            {label}
          </ButtonWrapper>
        </a>
      )}
      {to && (
        <Link to={to} style={{ textDecoration: "none" }}>
          <ButtonWrapper {...props}>{label}</ButtonWrapper>
        </Link>
      )}
    </>
  );
};

export default Button;
