import React, { useEffect, useState } from "react";
import styled from "styled-components";
import P1 from "./styled/P1";
import Button from "./button";

const Bannerwrapper = styled.div`
  width: 100%;
  padding: 1em;
  background: #ff4d07;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9em;
  p {
    color: white;
  }
  ${props =>
    props.disabled &&
    `
      display: none;
    `}
  @media (max-width: 640px) {
    flex-direction: column;
    padding-bottom: 1.7em;
  }
`;

const TopBanner = props => {
  const { body, label, url } = props;

  const cookie = props.cookie ? `-${props.cookie}` : "";

  const [okClicked, setOkClicked] = useState(true);

  useEffect(() => {
    const okClickedStored = localStorage.getItem(`banner-dismissed${cookie}`);
    if (okClickedStored) {
      setOkClicked(true);
    } else {
      setOkClicked(false);
    }
  }, []);

  return (
    <Bannerwrapper disabled={okClicked}>
      <P1 big>{body}</P1>
      <Button
        style={{ margin: "0 1em" }}
        white
        label={label}
        href={url}
        onClick={() => {
          setOkClicked(true);
          localStorage.setItem(`banner-dismissed${cookie}`, "true");
        }}
      />
    </Bannerwrapper>
  );
};

export default TopBanner;
