import React from "react";
import styled from "styled-components";
import H4 from "./styled/H4";
import colors from "./colors";
import H2 from "./styled/H2";

const SectionWrapper = styled.div`
  position: relative;
  :before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: ${props => (props.darkbg ? colors.bg : "transparent")};
    ${props =>
      props.skew &&
      `
      transform: skewY(${props.skew});
    `}
  }
  z-index: 1;
  :nth-last-child(2) {
    flex: 1 0 0;
  }
  > div {
    max-width: ${props => (props.wide ? "78em" : "64em")};
    margin: 0 auto;
    padding-top: ${props => (props.nomargin ? "0em" : "4em")};
    padding-bottom: 4em;
    padding-right: 2em;
    padding-left: 2em;
    @media (max-width: 640px) {
      padding-right: 1.2em;
      padding-left: 1.2em;
    }
    display: flex;
    flex-direction: column;
    align-items: ${props => (props.centered ? "center" : "flex-start")};
  }
  > div > img {
    max-width: 100%;
  }
`;

const Section = props => {
  const allPropsButTitle = { ...props, title: undefined };

  return (
    <SectionWrapper {...allPropsButTitle}>
      <div style={props.style}>
        {props.title && !props.description ? <H2 style={{ marginBottom: "2em" }}>{props.title}</H2> : ""}
        {props.title && props.description ? <H2>{props.title}</H2> : ""}
        {props.description ? (
          <H4 style={{ marginBottom: !props.nopadding ? "3em" : "0em" }}>{props.description}</H4>
        ) : (
          ""
        )}
        {props.children}
      </div>
    </SectionWrapper>
  );
};

export default Section;
