import styled from "styled-components";
import colors from "../colors";

const H4 = styled.h4`
  font-size: 1.4em;
  line-height: 1.6;
  text-align: ${props => (props.left ? "left" : "center")};
  align-self: ${props => (props.left ? "flex-start" : "auto")};
  color: ${props => (props.dark ? colors.ultra_dark : props.light ? colors.grey : colors.dark)};
  font-weight: 500;
  margin: 0.1em 0 0.5em;
  max-width: ${props => (props.small ? "14em" : "30em")};
`;

export default H4;
