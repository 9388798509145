import styled from "styled-components";
import colors from "../colors";

const MenuLinkWrapper = styled.div`
  display: inline-block;
  flex: 0 0 auto;
  padding: 0 1em;
  :last-child {
    padding-right: 0;
  }
  a {
    color: ${colors.grey};
    font-size: ${props => (props.big ? "1.4em" : "1.2em")};
    text-decoration: none;
  }
  @media (max-width: 640px) {
    ${props => props.nomobile && "display: none"}
    padding: 0 .5em;
  }
  .active {
    color: ${colors.accent};
  }
`;

export default MenuLinkWrapper;
