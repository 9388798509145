import styled from "styled-components";
import colors from "../colors";

const H2 = styled.h2`
  font-size: 2.1em;
  font-weight: 500;
  text-align: ${props => (props.left ? "left" : "center")};
  color: ${colors.ultra_dark};
  color: ${props => (props.dark ? colors.ultra_dark : props.light ? colors.dark : colors.ultra_dark)};
  margin: ${props => (props.bigmargin ? "1.8em" : "0.2em")} 0 0.4em;
  a {
    text-decoration: none;
  }
  ${props =>
    props.huge &&
    `
    font-size: 2.5em;
    font-weight: 600;
    margin: 0.2em 0 0.3em;
  `}
`;

export default H2;
