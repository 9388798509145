import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

function SEO({ description, lang, meta, card, icon, keywords, title }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription = description || data.site.siteMetadata.description;

        let metaTitle = `${title} | ${data.site.siteMetadata.title}`;

        if (title.includes("Unreadit")) {
          metaTitle = title;
        } else if (title === " ") {
          metaTitle = data.site.siteMetadata.title;
        }

        const metaCard = card ? `https://unread.it/images/cards/${card}.png` : "https://unread.it/card.png";

        const metaIcon = icon ? `https://unread.it/images/favicons/${icon}.png` : "apple-touch-icon-precomposed.png";

        return (
          <Helmet
            htmlAttributes={{ lang }}
            title={title}
            titleTemplate={metaTitle}
            meta={[
              { name: "description", content: metaDescription },
              { property: "og:title", content: metaTitle },
              { property: "og:description", content: metaDescription },
              { property: "og:image", content: metaCard },
              { property: "og:type", content: "website" },
              { name: "twitter:text:title", content: metaTitle },
              { name: "twitter:card", content: "summary_large_image" },
              { name: "twitter:image", content: metaCard },
              { name: "twitter:creator", content: data.site.siteMetadata.author },
              { name: "twitter:description", content: metaDescription }
            ]
              .concat(keywords.length > 0 ? { name: "keywords", content: keywords.join(", ") } : [])
              .concat(meta)}
            script={[
              {
                innerHTML:
                  "!function(e,t,n,c,o,a,f){e.fbq||(o=e.fbq=function(){o.callMethod?o.callMethod.apply(o,arguments):o.queue.push(arguments)},e._fbq||(e._fbq=o),(o.push=o).loaded=!0,o.version='2.0',o.queue=[],(a=t.createElement(n)).async=!0,a.src='https://connect.facebook.net/en_US/fbevents.js',(f=t.getElementsByTagName(n)[0]).parentNode.insertBefore(a,f))}(window,document,'script'),fbq('init','804850529878849'),fbq('track','PageView');"
              },
              {
                innerHTML:
                  "!function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments)},s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='//static.ads-twitter.com/uwt.js',a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');twq('init','o1otz');twq('track','PageView');"
              },
              {
                innerHTML:
                  '(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);'
              }
            ]}
            link={[
              { rel: "apple-touch-icon", type: "image/png", href: metaIcon },
              {
                rel: "preload",
                href: "../../static/fonts/brandon-light.woff",
                as: "font",
                type: "fonts/woff",
                crossorigin: "anonymous"
              },
              {
                rel: "preload",
                href: "../../static/fonts/brandon-regular.woff",
                as: "font",
                type: "fonts/woff",
                crossorigin: "anonymous"
              },
              {
                rel: "preload",
                href: "../../static/fonts/brandon-medium.woff",
                as: "font",
                type: "fonts/woff",
                crossorigin: "anonymous"
              },
              {
                rel: "preload",
                href: "../../static/fonts/brandon-bold.woff",
                as: "font",
                type: "fonts/woff",
                crossorigin: "anonymous"
              },
              {
                rel: "preload",
                href: "../../static/fonts/brandon-black.woff",
                as: "font",
                type: "fonts/woff",
                crossorigin: "anonymous"
              }
            ]}
          />
        );
      }}
    />
  );
}

SEO.defaultProps = {
  lang: "en",
  meta: [],
  keywords: []
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`;
