import React from "react";
import Section from "./Section";
import LinksWrapper from "./styled/LinksWrapper";
import MenuLinkWrapper from "./styled/MenuLinkWrapper";
import { Link } from "gatsby";

const Footer = props => (
  <>
    <Section {...props}>
      <LinksWrapper>
        <MenuLinkWrapper big>
          <Link to="/privacy/">Privacy Policy</Link>
        </MenuLinkWrapper>
        <MenuLinkWrapper big>
          <Link to="/cookie/">Cookie Policy</Link>
        </MenuLinkWrapper>
        <MenuLinkWrapper big>
          <a href="mailto:hello@unread.it">Say Hi</a>
        </MenuLinkWrapper>
      </LinksWrapper>
    </Section>
  </>
);

export default Footer;
