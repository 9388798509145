import React, { useContext } from "react";
import styled from "styled-components";
import { Logo } from "./logo";
import MenuLinkWrapper from "./styled/MenuLinkWrapper";
import { Link } from "gatsby";
import { ProductContext } from "../contexts/ProductContext";

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 60em;
  max-width: 100%;
  margin: 0 auto;
  padding: 1.5em;
  @media (max-width: 640px) {
    padding-right: 1em;
    padding-left: 1em;
  }
  z-index: 1;
`;

const LogoWrapper = styled.div`
  flex: 1 0 auto;
`;

const Header = () => {
  const product = useContext(ProductContext);

  return (
    <HeaderWrapper>
      <LogoWrapper>
        <Logo responsive link="/" product={product} />
      </LogoWrapper>

      <MenuLinkWrapper>
        <Link activeClassName="active" partiallyActive={true} to="blog/">
          Blog
        </Link>
      </MenuLinkWrapper>
    </HeaderWrapper>
  );
};

export default Header;
