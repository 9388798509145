import styled from "styled-components";
import colors from "../colors";

const P1 = styled.p`
  font-size: ${props => (props.big ? "1.4em" : "1.25em")};
  text-align: ${props => (props.centered ? "center" : "left")};
  color: ${props => (props.dark ? colors.ultra_dark : colors.dark)};
  color: ${props => (props.dark ? colors.ultra_dark : props.light ? colors.grey : colors.dark)};
  margin: 0.2em 0 0.3em;
  line-height: 1.5;
  ${props =>
    props.serif &&
    `
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    color: ${colors.ultra_dark};
    margin: 0.3em 0 0.8em;
    font-weight: 400;
    line-height: 1.8;
    font-size: 1.15em;
  `}
`;

export default P1;
