import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import colors from "./colors";
import Header from "./header";
import "./layout.css";
import Footer from "./footer";
import TopBanner from "./topBanner";

const LayoutWrapper = styled.div`
  max-width: 100vw;
  min-height: 100vh;
  overflow: hidden;
  background-color: ${props => (props.darkbg ? colors.bg : "#fff")};
  display: flex;
  flex-direction: column;
`;

const Layout = props => {
  const { body, label, url, cookie } = props.banner || {};

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={() => (
        <LayoutWrapper darkbg={props.darkbg}>
          {body && <TopBanner body={body} label={label} url={url} cookie={cookie} />}
          <Header />
          {props.children}
          <Footer darkbg />
        </LayoutWrapper>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
