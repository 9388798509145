import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import colors from "./colors";
import svgSprites from "./svgSprites";

const LogoWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  padding-bottom: 0.4em;
  text-decoration: none;
  a {
    text-decoration: none !important;
  }
  img {
    width: ${props => {
      switch (props.size) {
        case "small":
          return "16px";
        case "medium":
          return "30px";
        case "big":
          return "50px";
        default:
          return "30px";
      }
    }};
  }
  span {
    color: ${colors.accent};
    margin-left: 0.2em;
    font-size: ${props => {
      switch (props.size) {
        case "small":
          return "1.2em";
        case "medium":
          return "1.9em";
        case "big":
          return "3em";
        default:
          return "1.9em";
      }
    }};
    @media (max-width: 640px) {
      display: ${props => (props.responsive ? "none" : "initial")};
    }
  }
`;

const Logo = props => {
  const size = props.size || "medium";
  const product = props.product || "default";

  return (
    <Link style={{ textDecoration: "none" }} to={props.link}>
      <LogoWrapper {...props}>
        {svgSprites.logo[product][size]}
        <span>unreadit</span>
      </LogoWrapper>
    </Link>
  );
};

export { Logo };
