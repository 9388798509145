export default {
  ultra_dark: "#4F4B65",
  dark: "#838094",
  grey: "#B8B6C4",
  light: "#DFDEE4",
  lighter: "hsl(240, 14%, 96%)",
  ultra_light: "#F9F9FA",
  bg: "#FBF9F9",
  accent: "#EF1B1B",
  accentTransparent: "hsla(360, 87%, 52%, 0.2)"
};
