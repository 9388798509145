import styled from "styled-components";

const LinksWrapper = styled.div`
  text-align: center;
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  /* align-content: center; */
  justify-content: center;
  @media (max-width: 640px) {
    min-height: 6em;
  }
`;

export default LinksWrapper;
